<template>
  <div id="container" v-if="fetch_data">

    <section id="mypage">

      <div class="wrap">
        <TimelineQrcode />

        <!-- 各種設定と活動状況 -->
        <div class="cts_box">
          <h2 class="sub_ttl"><span>各種設定と活動状況</span></h2>
          <p class="lead_txt">複眼経済塾WEBサイトの機能の設定と、現在のフォロー中、フォロワーの塾生一覧を表示しています。</p>

          <div class="cts_box">
            <form @submit="userSearch">
              <h3 class="min_ttl">塾生を検索する</h3>
              <p class="lead_txt">「ユーザーネーム（表示名）」で塾生を検索します。</p>
              <div class="free_search_box">
                <input type="search" class="free_search full_width" placeholder="ユーザーネーム（表示名）で検索" v-model="user_search">
              </div>
              <input type="submit" class="submit light skeleton" value="塾生を検索">
            </form>
          </div>
        </div>

        <!--通知機能-->
        <div id="menu01" class="anchor_point"></div>
        <div class="cts_box">
          <div class="bdr_box">
            <h3 class="min_ttl">複眼経済塾WEBサイト通知機能</h3>
            <p>
              こちらでメール・WEB上で通知する内容をお選びいただけます。通知が不要なスペースは「×」を押して下記から削除してください。<br>
              （削除したものを追加するには、枠内をクリックします。すると選択肢が表示されますので、通知が欲しいスペースを全て選択してください。）
            </p>

            <div class="group_arart">
              <h4 class="bold">講義の新規投稿通知</h4>
              <ul class="inverse tagIt contents">
                <li v-for="(tag, index) in snsAlertSpaceContetTitles()" :key="`contents-${index}`">
                  <a>{{ tag }}</a>
                </li>
              </ul>
            </div>

            <div class="group_arart">
              <h4 class="bold">テーマ別グループの新規投稿通知</h4>
              <ul class="inverse tagIt social">
                <li v-for="(tag, index) in snsAlertSpaceSocialTitles()" :key="`social-${index}`">
                  <a>{{ tag }}</a>
                </li>
              </ul>
            </div>

            <div class="group_arart">
              <h4 class="bold">事務局通信・研修会参加者限定スペース・メディア出演情報、他の新規投稿通知</h4>
              <ul class="inverse tagIt media">
                <li v-for="(tag, index) in snsAlertSpaceMediaTitles()" :key="`media-${index}`">
                  <a>{{ tag }}</a>
                </li>
              </ul>
            </div>

            <ul class="sns_notice_list">
              <h4 class="bold mb20">メール・WEB上で通知を受け取りたい内容にチェックを入れて下さい。チェックをはずした項目は通知されません。</h4>
              <li>
                <div class="checkbox">
                  <input id="sns_notice02" type="checkbox" v-model="current_user.sns_alert_timeline"
                         @change="changeSettings()">
                  <label for="sns_notice02">
                    <h4 class="bold">タイムライン新規投稿通知</h4>
                    自分、またはフォローしているアカウントのタイムラインに新たな投稿があった場合に通知します
                  </label>
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <input id="sns_notice03" type="checkbox" v-model="current_user.sns_alert_post"
                         @change="changeSettings()">
                  <label for="sns_notice03">
                    <h4 class="bold">フォローしているアカウントの新規投稿・全通知</h4>
                    フォローしているアカウントが、タイムライン・グループ等に新たな投稿を行った場合に通知します
                  </label>
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <input id="sns_notice04" type="checkbox" v-model="current_user.sns_alert_like"
                         @change="changeSettings()">
                  <label for="sns_notice04">
                    <h4 class="bold">いいね！通知</h4>
                    自分の投稿した記事やコメントに対して「いいね！」が押された場合に通知します
                  </label>
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <input id="sns_notice06" type="checkbox" v-model="current_user.sns_alert_follow"
                         @change="changeSettings()">
                  <label for="sns_notice06">
                    <h4 class="bold">フォロワー登録通知</h4>
                    自分のアカウントが誰かにフォローされた時に通知します
                  </label>
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <input id="sns_notice07" type="checkbox" v-model="current_user.sns_alert_post_comment"
                         @change="changeSettings()">
                  <label for="sns_notice07">
                    <h4 class="bold">投稿記事・コメント リアクション通知</h4>
                    自分が投稿した記事やコメントに対して、誰かが返信を行った場合に通知します
                  </label>
                </div>
              </li>
            </ul>

            <div class="mail_settings">
              <p class="bold">
                フォロー・コメント・いいね！等をメールでお知らせします。<br>
                メールでお知らせするタイミングを選んでください。
              </p>
            </div>
            <div class="toggle_menu flex">
              <div class="toggle_menu_list flex">
                <div class="list bold">メールでお知らせをする</div>
                <div class="toggle_btn flex" v-bind:class="{'on': current_user.sns_mail_send}">
                  <p class="no">お知らせしない</p>
                  <div class="toggle_wrap"
                       @click="current_user.sns_mail_send = !current_user.sns_mail_send; changeSettings()">
                    <span></span>
                  </div>
                  <p class="yes">お知らせする</p>
                </div>
              </div>

              <!--上のお知らせを希望した場合表示-->
              <template v-if="current_user.sns_mail_send">

                <div class="radio sns_notice_time">
                  <div class="toggle_menu_list flex">
                    <div class="list bold">すぐにお知らせをする</div>
                    <div class="toggle_btn flex">
                      <div class="radio_wrap">
                        <input id="time_set01" type="radio" name="通知設定" v-model="current_user.sns_mail_type"
                               value="sns_mail_type_now" @change="changeSettings()">
                        <label for="time_set01"><span></span></label>
                      </div>
                    </div>
                  </div>

                  <div class="toggle_menu_list flex">
                    <div class="list bold">時間を指定してお知らせする</div>
                    <div class="toggle_btn flex">
                      <div class="radio_wrap">
                        <input id="time_set02" type="radio" name="通知設定" v-model="current_user.sns_mail_type"
                               value="sns_mail_type_time" @change="changeSettings()">
                        <label for="time_set02"><span></span></label>
                      </div>
                    </div>
                  </div>

                  <template v-if="current_user.sns_mail_type === 'sns_mail_type_time'">
                    <!--上のお知らせで時間指定を希望した場合表示 ループ-->
                    <div class="notice_time flex">
                      <div class="time_list flex">
                        <select v-model="current_user.sns_mail_time1" @change="changeSettings()">
                          <option value="00:00">00:00</option>
                          <option value="00:30">00:30</option>
                          <option value="01:00">01:00</option>
                          <option value="01:30">01:30</option>
                          <option value="02:00">02:00</option>
                          <option value="02:30">02:30</option>
                          <option value="03:00">03:00</option>
                          <option value="03:30">03:30</option>
                          <option value="04:00">04:00</option>
                          <option value="04:30">04:30</option>
                          <option value="05:00">05:00</option>
                          <option value="05:30">05:30</option>
                          <option value="06:00">06:00</option>
                          <option value="06:30">06:30</option>
                          <option value="07:00">07:00</option>
                          <option value="07:30">07:30</option>
                          <option value="08:00">08:00</option>
                          <option value="08:30">08:30</option>
                          <option value="09:00">09:00</option>
                          <option value="09:30">09:30</option>
                          <option value="10:00">10:00</option>
                          <option value="10:30">10:30</option>
                          <option value="11:00">11:00</option>
                          <option value="11:30">11:30</option>
                          <option value="12:00">12:00</option>
                          <option value="12:30">12:30</option>
                          <option value="13:00">13:00</option>
                          <option value="13:30">13:30</option>
                          <option value="14:00">14:00</option>
                          <option value="14:30">14:30</option>
                          <option value="15:00">15:00</option>
                          <option value="15:30">15:30</option>
                          <option value="16:00">16:00</option>
                          <option value="16:30">16:30</option>
                          <option value="17:00">17:00</option>
                          <option value="17:30">17:30</option>
                          <option value="18:00">18:00</option>
                          <option value="18:30">18:30</option>
                          <option value="19:00">19:00</option>
                          <option value="19:30">19:30</option>
                          <option value="20:00">20:00</option>
                          <option value="20:30">20:30</option>
                          <option value="21:00">21:00</option>
                          <option value="21:30">21:30</option>
                          <option value="22:00">22:00</option>
                          <option value="22:30">22:30</option>
                          <option value="23:00">23:00</option>
                          <option value="23:30">23:30</option>
                        </select>
                        <p>にお知らせする</p>
                      </div>
                      <div class="btn">
                        <a href="javascript:void(0)" class="submit light" id="time_delete"
                           v-if="current_user.sns_mail_time1" @click="deleteMailTime1()">時間を削除</a>
                      </div>
                    </div>
                    <!--//上のお知らせで時間指定を希望した場合表示 ループ-->
                    <div class="addition btn">
                      <a href="javascript:void(0)" @click="show_mail_time2 = true"
                         v-if="!show_mail_time2 && current_user.sns_mail_time1" class="submit light skeleton"
                         id="time_create">お知らせの時間を追加</a>
                    </div>

                    <div class="notice_time flex" v-if="show_mail_time2">
                      <div class="time_list flex">
                        <select v-model="current_user.sns_mail_time2" @change="changeSettings()">
                          <option value="00:00">00:00</option>
                          <option value="00:30">00:30</option>
                          <option value="01:00">01:00</option>
                          <option value="01:30">01:30</option>
                          <option value="02:00">02:00</option>
                          <option value="02:30">02:30</option>
                          <option value="03:00">03:00</option>
                          <option value="03:30">03:30</option>
                          <option value="04:00">04:00</option>
                          <option value="04:30">04:30</option>
                          <option value="05:00">05:00</option>
                          <option value="05:30">05:30</option>
                          <option value="06:00">06:00</option>
                          <option value="06:30">06:30</option>
                          <option value="07:00">07:00</option>
                          <option value="07:30">07:30</option>
                          <option value="08:00">08:00</option>
                          <option value="08:30">08:30</option>
                          <option value="09:00">09:00</option>
                          <option value="09:30">09:30</option>
                          <option value="10:00">10:00</option>
                          <option value="10:30">10:30</option>
                          <option value="11:00">11:00</option>
                          <option value="11:30">11:30</option>
                          <option value="12:00">12:00</option>
                          <option value="12:30">12:30</option>
                          <option value="13:00">13:00</option>
                          <option value="13:30">13:30</option>
                          <option value="14:00">14:00</option>
                          <option value="14:30">14:30</option>
                          <option value="15:00">15:00</option>
                          <option value="15:30">15:30</option>
                          <option value="16:00">16:00</option>
                          <option value="16:30">16:30</option>
                          <option value="17:00">17:00</option>
                          <option value="17:30">17:30</option>
                          <option value="18:00">18:00</option>
                          <option value="18:30">18:30</option>
                          <option value="19:00">19:00</option>
                          <option value="19:30">19:30</option>
                          <option value="20:00">20:00</option>
                          <option value="20:30">20:30</option>
                          <option value="21:00">21:00</option>
                          <option value="21:30">21:30</option>
                          <option value="22:00">22:00</option>
                          <option value="22:30">22:30</option>
                          <option value="23:00">23:00</option>
                          <option value="23:30">23:30</option>
                        </select>
                        <p>にお知らせする</p>
                      </div>
                      <div class="btn">
                        <a href="javascript:void(0)" class="submit light" id="time_delete2"
                           v-if="current_user.sns_mail_time2" @click="deleteMailTime2()">時間を削除</a>
                      </div>
                    </div>
                  </template>
                </div>
                <!--//上のお知らせを希望した場合表示-->
              </template>
            </div>
          </div>
        </div>

        <div class="cts_box" v-if="fetch_data.recommends.length > 0">
          <h3 class="min_ttl">フォローのおすすめ</h3>
          <p class="lead_txt">あなたと共通する話題をお持ちの塾生です。こちらの方々もフォローしてみてはいかがでしょうか？</p>
          <div class="follow_list flex">
            <div class="follow_box" v-for="user in fetch_data.recommends" :key="user.id">
              <div class="icon">
                <router-link :to="{ name: 'SnsTimeline', params: {user_id: user.id}}">
                  <img v-bind:src="user.profile_image_url" alt="">
                </router-link>
              </div>
              <h3>{{ user.sns_user_name }}</h3>
              <a href="javascript:void(0)" class="btn_follow" @click="follow(user)">フォローする</a>
            </div>
          </div>
        </div>

        <div id="menu02" class="anchor_point"></div>
        <div class="cts_box">
          <h3 class="min_ttl">フォロー中の塾生</h3>
          <p class="lead_txt">現在フォローしている会員の方々です。フォローを終了する場合は「フォロー中」の表示をクリックしてください。</p>
          <h4 class="bold">現在{{ delimited(fetch_data.follows.follows_count) }}人フォローしています。</h4>
          <div class="sns_user_list flex">
            <div class="sns_user flex" v-for="user in fetch_data.follows.follows" :key="user.id"
                 v-bind:class="{'mutual_follow': user.mutual}">
              <div class="sns_icon">
                <router-link :to="{ name: 'SnsTimeline', params: {user_id: user.id}}">
                  <img v-bind:src="user.profile_image_url" alt="">
                </router-link>
              </div>
              <div class="user_name">{{ user.sns_user_name }}</div>
              <div class="btn unfollow"><a href="javascript:void(0);" @click="unFollow(user)">フォロー解除</a></div>
            </div>
          </div>
          <!--pager-->
          <Pagination :pagination="fetch_data.follows.pagination" v-on:changed="followsPageChanged"/>
          <!--//pager-->
        </div>

        <div id="menu03" class="anchor_point"></div>
        <div class="cts_box">
          <h3 class="min_ttl">フォロワーの塾生</h3>
          <p class="lead_txt">現在あなたをフォローしている塾生の方々です。フォローする場合は「フォローする」の表示をクリックしてください。</p>
          <h4 class="bold">現在{{ delimited(fetch_data.followers.followers_count) }}人の方があなたをフォローしています。</h4>
          <div class="sns_user_list flex">
            <div class="sns_user flex" v-for="user in fetch_data.followers.followers" :key="user.id"
                 v-bind:class="{'mutual_follow': user.mutual}">
              <div class="sns_icon">
                <router-link :to="{ name: 'SnsTimeline', params: {user_id: user.id}}">
                  <img v-bind:src="user.profile_image_url" alt="">
                </router-link>
              </div>
              <div class="user_name">{{ user.sns_user_name }}</div>
              <div class="btn unfollow" v-if="user.mutual"><a href="javascript:void(0);"
                                                              @click="unFollow(user)">フォロー解除</a></div>
              <div class="btn" v-if="!user.mutual"><a href="javascript:void(0);" @click="follow(user)">フォローする</a></div>
            </div>
          </div>
          <!--pager-->
          <Pagination :pagination="fetch_data.followers.pagination" v-on:changed="followersPageChanged"/>
          <!--//pager-->
        </div>

        <div id="menu04" class="anchor_point"></div>
        <div class="cts_box">
          <div class="bdr_box">
            <h3 class="min_ttl">フォロー機能</h3>
            <div class="all_follow flex">
              <p class="bold">
                すべての塾生を一括フォローする場合は右の「一括フォロー」ボタンを押してください。<br>
                今後の新規入塾者も自動的にフォローされます。
              </p>
              <div class="btn">
                <a href="javascript:void(0);" class="submit light" @click="allFollow()">一括フォロー</a>
              </div>

              <p class="bold mt20">
                すべての塾生のフォローを解除したい場合は「一括解除」ボタンを押してください。<br>&nbsp;
              </p>
              <div class="btn">
                <a href="javascript:void(0);" class="submit light" @click="allUnFollow()">一括解除</a>
              </div>
            </div>

            <div class="toggle_menu flex">
              <div class="toggle_menu_list flex">
                <div class="list bold">フォローされたら自動的にフォローを返す</div>
                <div class="toggle_btn flex" v-bind:class="{'on': current_user.sns_auto_follow}">
                  <p class="no">返さない</p>
                  <div class="toggle_wrap"
                       @click="current_user.sns_auto_follow = !current_user.sns_auto_follow; changeSettings()">
                    <span></span>
                  </div>
                  <p class="yes">返す</p>
                </div>
              </div>
              <div class="toggle_menu_list flex">
                <div class="list bold">新規入塾者を自動でフォローする</div>
                <div class="toggle_btn flex" v-bind:class="{'on': current_user.sns_mutual_follow}">
                  <p class="no">フォローしない</p>
                  <div class="toggle_wrap"
                       @click="current_user.sns_mutual_follow = !current_user.sns_mutual_follow; changeSettings()">
                    <span></span>
                  </div>
                  <p class="yes">フォローする</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="menu05" class="anchor_point"></div>
        <div class="cts_box">
          <h3 class="min_ttl">ミュートしている塾生</h3>
          <p class="lead_txt">ミュートしているユーザーの投稿やコメントは表示されません。ミュートを解除する場合は「ミュート解除」の表示をクリックしてください。</p>
          <h4 class="bold">現在{{ delimited(fetch_data.mutes.mutes_count) }}人の方をミュートしています。</h4>
          <div class="sns_user_list flex">
            <div class="sns_user flex mute" v-for="user in fetch_data.mutes.mutes" :key="user.id">
              <div class="sns_icon">
                <router-link :to="{ name: 'SnsTimeline', params: {user_id: user.id}}">
                  <img v-bind:src="user.profile_image_url" alt="">
                </router-link>
              </div>
              <div class="user_name">{{ user.sns_user_name }}</div>
              <div class="btn unfollow"><a href="javascript:void(0);" @click="unMute(user)">ミュート解除</a></div>
            </div>
          </div>
          <!--pager-->
          <Pagination :pagination="fetch_data.mutes.pagination" v-on:changed="mutesPageChanged"/>
          <!--//pager-->
        </div>

        <div id="menu06" class="anchor_point"></div>
        <div class="cts_box">
          <h3 class="min_ttl">ブロック中の塾生</h3>
          <p class="lead_txt">ブロック中のユーザーはあなたの投稿やコメントを見ることができません。またブロック中のユーザーの投稿やコメントは表示されません。<br>ブロックを解除する場合は「ブロック解除」の表示をクリックしてください。
          </p>
          <h4 class="bold">現在{{ delimited(fetch_data.blocks.blocks_count) }}人の方をブロックしています。</h4>
          <div class="sns_user_list flex">
            <div class="sns_user flex block" v-for="user in fetch_data.blocks.blocks" :key="user.id">
              <div class="sns_icon">
                <router-link :to="{ name: 'SnsTimeline', params: {user_id: user.id}}">
                  <img v-bind:src="user.profile_image_url" alt="">
                </router-link>
              </div>
              <div class="user_name">{{ user.sns_user_name }}</div>
              <div class="btn unfollow"><a href="javascript:void(0);" @click="unBlock(user)">ブロック解除</a></div>
            </div>
          </div>
          <!--pager-->
          <Pagination :pagination="fetch_data.blocks.pagination" v-on:changed="blocksPageChanged"/>
          <!--//pager-->
        </div>

        <div>
          <div id="menu07" class="anchor_point"></div>
          <div class="cts_box">
            <h3 class="min_ttl">あなたの複眼経済塾WEBサイト活動内容</h3>
            <p class="lead_txt">複眼経済塾WEBサイトの機能のご利用状況に応じて、以下のようにスコアが追加されます。</p>

            <div class="score_detail">
              <h4 class="bold mb10">複眼経済塾WEBサイト内での行動によるスコア追加</h4>
              <div class="score_detail_list flex">
                <div class="detail icon post">複眼経済塾WEBサイトへの投稿：5点</div>
                <div class="detail icon comment_post">コメントを投稿：4点</div>
                <div class="detail icon iine_plus">「いいね！」を追加：3点</div>
              </div>
              <h4 class="bold mb10">複眼経済塾WEBサイト内でうけたリアクションによるスコア追加</h4>
              <div class="score_detail_list flex">
                <div class="detail icon comment">コメントが追加された：2点</div>
                <div class="detail icon iine">「いいね！」された：1点</div>
              </div>
            </div>
            <p class="lead_txt">あなたの複眼経済塾WEBサイトの利用実績の合計スコアにもとづいて、月間・四半期・年間の順位の算出を行います。</p>
            <h4 class="bold mb10">現在のランキング（進捗状況）</h4>

            <div class="bdr_box">
              <table class="sns_activities">
                <tbody>
                <tr class="header">
                  <td>&nbsp;</td>
                  <td>月間<span>{{ moment(sns.score.term_real_monthly.start_date, 'YYYY年') }}<br
                      class="sp">{{ moment(sns.score.term_real_monthly.start_date, 'MM月') }}</span></td>
                  <td>四半期<span>{{ moment(sns.score.term_real_quarter.start_date, 'YYYY年') }}<br class="sp">{{
                      moment(sns.score.term_real_quarter.start_date, 'MM月')
                    }}〜{{ moment(sns.score.term_real_quarter.end_date, 'MM月') }}</span></td>
                  <td>年間<span>{{ moment(sns.score.term_real_yearly.start_date, 'YYYY年') }}<br
                      class="sp">1月〜12月</span></td>
                  <td class="total">累計</td>
                </tr>
                <tr class="real_rank">
                  <th><span class="icon_real_rank">順位</span></th>
                  <td><span>{{ sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.rank) : '-' }}</span>
                    位
                  </td>
                  <td><span>{{ sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.rank) : '-' }}</span>
                    位
                  </td>
                  <td><span>{{ sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.rank) : '-' }}</span> 位
                  </td>
                  <td><span>-</span> 位</td>
                </tr>
                <tr class="real_score">
                  <th><span class="icon_real_score">スコア合計</span></th>
                  <td><span>{{
                      sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{ sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.score) : '-' }}</span>
                    点
                  </td>
                  <td><span>{{ delimited(sns.score.total_score) }}</span> 点</td>
                </tr>
                <tr>
                  <th><span class="icon_real_post">複眼経済塾WEBサイトへの<br class="sp">投稿</span></th>
                  <td><span>{{
                      sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.post_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.post_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.post_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{ delimited(sns.score.total_post_score) }}</span> 点</td>
                </tr>
                <tr>
                  <th><span class="icon_real_comment_post">コメントを<br class="sp">投稿</span></th>
                  <td><span>{{
                      sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.comment_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.comment_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.comment_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{ delimited(sns.score.total_comment_score) }}</span> 点</td>
                </tr>
                <tr>
                  <th><span class="icon_real_iine_post">いいね！を<br class="sp">追加</span></th>
                  <td><span>{{
                      sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.like_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.like_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.like_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{ delimited(sns.score.total_like_score) }}</span> 点</td>
                </tr>
                <tr>
                  <th><span class="icon_real_comment">コメントが<br class="sp">追加</span></th>
                  <td><span>{{
                      sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.commented_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.commented_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.commented_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{ delimited(sns.score.total_commented_score) }}</span> 点</td>
                </tr>
                <tr>
                  <th><span class="icon_real_iine">いいね！<br class="sp">された</span></th>
                  <td><span>{{
                      sns.score.term_real_monthly ? delimited(sns.score.term_real_monthly.liked_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_quarter ? delimited(sns.score.term_real_quarter.liked_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{
                      sns.score.term_real_yearly ? delimited(sns.score.term_real_yearly.liked_score) : '-'
                    }}</span> 点
                  </td>
                  <td><span>{{ delimited(sns.score.total_liked_score) }}</span> 点</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h3 class="min_ttl">過去のランキング（実績）</h3>

          <div class="cts_box sns_ranking" v-if="fetch_data.term_monthly.ranks.length > 0">
            <h4 class="bold mb10">前月月間ランキング結果（{{ moment(fetch_data.term_monthly.start_date, 'YYYY年MM月') }}）</h4>
            <table class="type01">
              <tbody>
              <tr class="header">
                <th class="ranking">順位</th>
                <th class="user_name">ユーザーネーム（表示名）</th>
                <th class="score"><span class="icon star">スコア</span></th>
                <th class="compare">&nbsp;</th>
              </tr>
              <tr v-bind:class="{myrank: rank.user.id === current_user.id}"
                  v-for="(rank, index) in fetch_data.term_monthly.ranks" :key="`monthly-${index}`"
                  @click="snsRankClicked(rank)">
                <td class="ranking" :class="rankingClass(rank)">{{ rank.rank }}</td>
                <td class="user_name">
                  {{ rank.user.sns_user_name }}
                </td>
                <td class="score"><span>{{ delimited(rank.score) }}</span>点</td>
                <td class="compare">
                  <div class="comparison" :class="rankClass(rank, 'month')"></div>
                </td>
              </tr>
              </tbody>
            </table>
            <dl class="out_of_rank flex" v-if="fetch_data.term_monthly.user_rank">
              <dd class="ttl"><span>あなたのランキング</span></dd>
              <dd class="ranking">{{ fetch_data.term_monthly.user_rank.rank }}</dd>
              <dd class="user_name">{{ fetch_data.term_monthly.user_rank.user.sns_user_name }}</dd>
              <dd class="score"><span>{{ delimited(fetch_data.term_monthly.user_rank.score) }}</span>点</dd>
              <dd class="compare">
                <div class="comparison" :class="rankClass(fetch_data.term_monthly.user_rank, 'monthly')"></div>
              </dd>
            </dl>
          </div>

          <div class="cts_box sns_ranking" v-if="fetch_data.term_quarter.ranks.length > 0">
            <h4 class="bold mb10">前四半期ランキング結果（{{
                moment(fetch_data.term_quarter.start_date, 'YYYY年MM月')
              }}〜{{ moment(fetch_data.term_quarter.end_date, 'MM月') }}）</h4>
            <table class="type01">
              <tbody>
              <tr class="header">
                <th class="ranking">順位</th>
                <th class="user_name">ユーザーネーム（表示名）</th>
                <th class="score"><span class="icon star">スコア</span></th>
                <th class="compare">&nbsp;</th>
              </tr>
              <tr v-bind:class="{myrank: rank.user.id === current_user.id}"
                  v-for="(rank, index) in fetch_data.term_quarter.ranks" :key="`weekly-${index}`"
                  @click="snsRankClicked(rank)">
                <td class="ranking" :class="rankingClass(rank)">{{ rank.rank }}</td>
                <td class="user_name">
                  {{ rank.user.sns_user_name }}
                </td>
                <td class="score"><span>{{ delimited(rank.score) }}</span>点</td>
                <td class="compare">
                  <div class="comparison" :class="rankClass(rank, 'week')"></div>
                </td>
              </tr>
              </tbody>
            </table>
            <dl class="out_of_rank flex" v-if="fetch_data.term_quarter.user_rank">
              <dd class="ttl"><span>あなたのランキング</span></dd>
              <dd class="ranking">{{ fetch_data.term_quarter.user_rank.rank }}</dd>
              <dd class="user_name">{{ fetch_data.term_quarter.user_rank.user.sns_user_name }}</dd>
              <dd class="score"><span>{{ delimited(fetch_data.term_quarter.user_rank.score) }}</span>点</dd>
              <dd class="compare">
                <div class="comparison" :class="rankClass(fetch_data.term_quarter.user_rank, 'quarter')"></div>
              </dd>
            </dl>
          </div>

          <div class="cts_box sns_ranking" v-if="fetch_data.term_yearly.ranks.length > 0">
            <h4 class="bold mb10">前年度ランキング結果（{{
                moment(fetch_data.term_yearly.start_date, 'YYYY年')
              }}1月〜12月）</h4>
            <table class="type01">
              <tbody>
              <tr class="header">
                <th class="ranking">順位</th>
                <th class="user_name">ユーザーネーム（表示名）</th>
                <th class="score"><span class="icon star">スコア</span></th>
                <th class="compare">&nbsp;</th>
              </tr>
              <tr v-bind:class="{myrank: rank.user.id === current_user.id}"
                  v-for="(rank, index) in fetch_data.term_yearly.ranks" :key="`yearly-${index}`"
                  @click="snsRankClicked(rank)">
                <td class="ranking" :class="rankingClass(rank)">{{ rank.rank }}</td>
                <td class="user_name">
                  {{ rank.user.sns_user_name }}
                </td>
                <td class="score"><span>{{ delimited(rank.score) }}</span>点</td>
                <td class="compare">
                  <div class="comparison" :class="rankClass(rank, 'year')"></div>
                </td>
              </tr>
              </tbody>
            </table>
            <dl class="out_of_rank flex" v-if="fetch_data.term_yearly.user_rank">
              <dd class="ttl"><span>あなたのランキング</span></dd>
              <dd class="ranking">{{ fetch_data.term_yearly.user_rank.rank }}</dd>
              <dd class="user_name">{{ fetch_data.term_yearly.user_rank.user.sns_user_name }}</dd>
              <dd class="score"><span>{{ delimited(fetch_data.term_yearly.user_rank.score) }}</span>点</dd>
              <dd class="compare">
                <div class="comparison" :class="rankClass(fetch_data.term_yearly.user_rank, 'yearly')"></div>
              </dd>
            </dl>
          </div>
        </div>

        <div class="button_wrap">
          <router-link :to="{name: 'UsersCustomerAccount'}" class="submit arrow back large light skeleton">
            マイページに戻る
          </router-link>
        </div>

      </div>
    </section>

    <!--塾生検索-->
    <div class="user_search modal" v-bind:class="{open: show_user_search_modal}">
      <div class="modal_box">
        <div class="header">
          <h4>「{{ user_search }}」の検索結果（{{ user_search_data.length }}件）</h4>
          <div class="close modal_close" @click="closeUserSearchModal()"></div>
        </div>
        <div class="inner">
          <p class="alnC mt20 mb20" v-if="user_search_data.length === 0">該当する塾生は見つかりませんでした。</p>
          <div class="user_search_list" v-else>
            <!--loop-->
            <div class="user_box flex" v-for="user in user_search_data" :key="user.id">
              <div class="icon">
                <router-link :to="{ name: 'SnsTimeline', params: {user_id: user.id}}">
                  <img v-bind:src="user.profile_image_url" alt="">
                </router-link>
              </div>
              <div class="name">{{ user.sns_user_name }}</div>
              <div class="btn">
                <div class="follow_btn" v-if="!user.follow" @click="follow(user, false); user.follow = true">フォローする
                </div>
                <div class="follow_btn active" v-else @click="unFollow(user, false) ; user.follow = false">フォロー解除
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="ovr"></div>
    </div>

    <!-- 保存アラート -->
    <div class="save-alert" v-if="updated">
      設定を保存しました。
    </div>
  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import Pagination from "@/components/Pagination";
import {sprintf} from "sprintf-js";
import TimelineQrcode from '@/views/users/customer/elements/account/_timeline_qrcode'

export default {
  mixins: [Libraries],
  components: {
    Pagination,
    TimelineQrcode
  },
  data() {
    return {
      fetch_data: null,
      sns: {},
      fetch_reload: false,
      show_mail_time2: false,
      user_search: null,
      user_search_data: [],
      social_titles: null,
      content_titles: null,
      media_titles: null,
      show_user_search_modal: false,
      alert_spaces_changed: false,
      init_tagit: true,
      updated: false
    }
  },
  mounted() {
    this.show_mail_time2 = (this.current_user.sns_mail_time2 != null)
    this.fetchData()
    // 定期更新インターバル
    window.setInterval(() => {
      if (this.alert_spaces_changed) this.changeSettings()
    }, 1000)
  },
  methods: {
    // sns情報取得
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}sns/dashboard.json`,)
          .then(response => {
            this.fetch_data = response.data
            this.sns.score = this.fetch_data.score
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
            if (this.init_tagit) this.tagit();
            this.init_tagit = false
            this.alert_spaces_changed = false
            if (location.hash) {
              setTimeout(() => {
                this.scrollTo(location.hash)
              }, 200)
            }
          })
    },

    // 講義・事務局通信で選択中のタイトルを配列で返す
    snsAlertSpaceContetTitles() {
      if (!this.content_titles) {
        this.content_titles = []
        this.fetch_data.content_rooms.forEach((room) => {
          this.fetch_data.alert_spaces.forEach((alert) => {
            if (room.sns_space_id === alert.sns_space_id && room.sns_space_room_id === alert.sns_space_room_id) {
              this.content_titles.push(room.title)
              return true
            }
          })
        })
      }
      return this.content_titles
    },

    // テーマ別グループで選択中のタイトルを配列で返す
    snsAlertSpaceSocialTitles() {
      if (!this.social_titles) {
        this.social_titles = []
        this.fetch_data.social_rooms.forEach((room) => {
          this.fetch_data.alert_spaces.forEach((alert) => {
            if (room.sns_space_id === alert.sns_space_id && room.sns_space_room_id === alert.sns_space_room_id) {
              this.social_titles.push(room.title)
              return true
            }
          })
        })
      }
      return this.social_titles
    },

    // メディア・事務局通信で選択中のタイトルを配列で返す
    snsAlertSpaceMediaTitles() {
      if (!this.media_titles) {
        this.media_titles = []
        this.fetch_data.media_rooms.forEach((room) => {
          this.fetch_data.alert_spaces.forEach((alert) => {
            if (room.sns_space_id === alert.sns_space_id && room.sns_space_room_id === alert.sns_space_room_id) {
              this.media_titles.push(room.title)
              return true
            }
          })
        })
      }
      return this.media_titles
    },
    // 一括フォロー
    allFollow() {
      if (!confirm('一括フォローを実行しますか？')) {
        return
      }
      this.startAjax()
      this.axios
          .post(`${this.env.api_base_url}sns/follow_users.json`)
          .then(() => {
            this.fetchData()
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },

    // 一括解除
    allUnFollow() {
      if (!confirm('フォローの一括解除を実行しますか？')) {
        return
      }
      this.startAjax()
      this.axios
          .post(`${this.env.api_base_url}sns/un_follow_users.json`)
          .then(() => {
            this.fetchData()
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },

    // 設定の変更
    changeSettings() {
      this.updated = false

      if (this.current_user.sns_mail_type === 'sns_mail_type_time' && !this.current_user.sns_mail_time1) {
        this.current_user.sns_mail_time1 = '07:00'
      }
      if (this.current_user.sns_mail_type === 'sns_mail_type_now') {
        this.current_user.sns_mail_time1 = this.current_user.sns_mail_time2 = null
      }

      // 講義・事務局通信・テーマ別グループの新規投稿通知
      let alert_spaces = []
      if (this.alert_spaces_changed) {
        const all_rooms = this.fetch_data.content_rooms.concat(this.fetch_data.social_rooms).concat(this.fetch_data.media_rooms)
        window.$('input[name="alert_contents"]').val().split(',')
            .concat(window.$('input[name="alert_media"]').val().split(','))
            .concat(window.$('input[name="alert_social"]').val().split(',')).forEach((title) => {
          if (title) {
            const room = all_rooms.find((room) => room.title === title);
            alert_spaces.push(`${room.sns_space_id}-${room.sns_space_room_id}`)
          }
        })
      }

      this.axios
          .post(`${this.env.api_base_url}users/update.json`, {
            user: {
              sns_auto_follow: this.current_user.sns_auto_follow,
              sns_mutual_follow: this.current_user.sns_mutual_follow,
              sns_alert_timeline: this.current_user.sns_alert_timeline,
              sns_alert_post: this.current_user.sns_alert_post,
              sns_alert_like: this.current_user.sns_alert_like,
              sns_alert_follow: this.current_user.sns_alert_follow,
              sns_alert_post_comment: this.current_user.sns_alert_post_comment,
              sns_mail_send: this.current_user.sns_mail_send,
              sns_mail_type: this.current_user.sns_mail_type,
              sns_mail_time1: this.current_user.sns_mail_time1,
              sns_mail_time2: this.current_user.sns_mail_time2,
              sns_alert_space_ids: this.alert_spaces_changed ? alert_spaces.join(',') : null
            },
          })
          .then(response => {
            this.saveSession(response.data.user);
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.alert_spaces_changed = false
            this.updated = true
            setTimeout(() => {
              this.updated = false
            }, 1500)
          })
    },

    deleteMailTime1() {
      this.current_user.sns_mail_time1 = null
      if (this.current_user.sns_mail_time2) {
        this.current_user.sns_mail_time1 = this.current_user.sns_mail_time2
        this.current_user.sns_mail_time2 = null
      } else {
        this.current_user.sns_mail_type = 'sns_mail_type_now'
      }
      this.show_mail_time2 = (this.current_user.sns_mail_time2 != null)
      this.changeSettings()
    },

    deleteMailTime2() {
      this.current_user.sns_mail_time2 = null
      this.show_mail_time2 = false
      this.changeSettings()
    },

    // フォローページネーション
    followsPageChanged(page) {
      //this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}sns/follows.json`, {
            params: {
              page: page
            }
          })
          .then(response => {
            this.fetch_data.follows.follows_count = response.data.follows_count
            this.fetch_data.follows.follows = response.data.follows
            this.fetch_data.follows.pagination = response.data.pagination
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },

    // フォロワーページネーション
    followersPageChanged(page) {
      //this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}sns/followers.json`, {
            params: {
              page: page
            }
          })
          .then(response => {
            this.fetch_data.followers.followers_count = response.data.followers_count
            this.fetch_data.followers.followers = response.data.followers
            this.fetch_data.followers.pagination = response.data.pagination
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },
    // フォロ
    follow(user, reload = true) {
      this.axios
          .post(`${this.env.api_base_url}sns/follow.json`, {
            user_id: user.id
          })
          .then(() => {
            if (reload) this.fetchData()
            else this.fetch_reload = true
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },

    // フォロー解除
    unFollow(user, reload = true) {
      this.axios
          .post(`${this.env.api_base_url}sns/un_follow.json`, {
            user_id: user.id
          })
          .then(() => {
            if (reload) this.fetchData()
            else this.fetch_reload = true
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },

    // ミュートページネーション
    mutesPageChanged(page) {
      //this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}sns/mutes.json`, {
            params: {
              page: page
            }
          })
          .then(response => {
            this.fetch_data.mutes.mutes_count = response.data.mutes_count
            this.fetch_data.mutes.mutes = response.data.mutes
            this.fetch_data.mutes.pagination = response.data.pagination
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },

    // ミュート解除
    unMute(user) {
      this.axios
          .post(`${this.env.api_base_url}sns/un_mute.json`, {
            user_id: user.id
          })
          .then(() => {
            this.fetchData()
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },

    // ブロックページネーション
    blocksPageChanged(page) {
      //this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}sns/blocks.json`, {
            params: {
              page: page
            }
          })
          .then(response => {
            this.fetch_data.blocks.blocks_count = response.data.blocks_count
            this.fetch_data.blocks.blocks = response.data.blocks
            this.fetch_data.blocks.pagination = response.data.pagination
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },

    // ブロック解除
    unBlock(user) {
      this.axios
          .post(`${this.env.api_base_url}sns/un_block.json`, {
            user_id: user.id
          })
          .then(() => {
            this.fetchData()
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },

    // 塾生検索
    userSearch(event) {
      event.preventDefault()
      if (this.user_search) {
        this.user_search_data = []
        this.axios
            .get(`${this.env.api_base_url}sns/user_search.json`, {
              params: {
                query: this.user_search
              }
            })
            .then(response => {
              this.user_search_data = response.data
              this.show_user_search_modal = true
            })
            .catch((response) => {
              this.errorAjax(response)
            })
            .finally(() => {
              //this.finishAjax()
            })
      }
    },

    closeUserSearchModal() {
      this.show_user_search_modal = false
      if (this.fetch_reload) {
        this.fetch_reload = false
        this.fetchData()
      }
    },
    tagit() {
      window.$('.tagIt.contents').tagit({
        placeholderText: "講義を追加",
        fieldName: 'alert_contents',
        singleFieldDelimiter: ',',
        singleField: true,
        readOnly: false,
        availableTags: this.fetch_data.content_rooms.map(value => value.title),
        showAutocompleteOnFocus: true,
        preprocessTag: ((tagLabel) => {
          if (!tagLabel) return tagLabel;
          return tagLabel;
        }),
        afterTagAdded: (() => {
          this.alert_spaces_changed = true
          // 動的に placeholderを変える
          if (window.$('input[name="alert_contents"]').val().split(',').length === this.fetch_data.content_rooms.length) {
            window.$('.tagIt.contents').data("ui-tagit").tagInput.attr('placeholder', '全て選択中');
          }
        }),
        afterTagRemoved: (() => {
          this.alert_spaces_changed = true
          // 動的に placeholderを変える
          window.$('.tagIt.contents').data("ui-tagit").tagInput.attr('placeholder', '講義を追加');
        }),
      });
      window.$('.tagIt.social').tagit({
        placeholderText: "グループを追加",
        fieldName: 'alert_social',
        singleFieldDelimiter: ',',
        singleField: true,
        readOnly: false,
        availableTags: this.fetch_data.social_rooms.map(value => value.title),
        showAutocompleteOnFocus: true,
        preprocessTag: ((tagLabel) => {
          if (!tagLabel) return tagLabel;
          return tagLabel;
        }),
        afterTagAdded: (() => {
          this.alert_spaces_changed = true
          // 動的に placeholderを変える
          if (window.$('input[name="alert_social"]').val().split(',').length === this.fetch_data.social_rooms.length) {
            window.$('.tagIt.social').data("ui-tagit").tagInput.attr('placeholder', '全て選択中');
          }
        }),
        afterTagRemoved: (() => {
          this.alert_spaces_changed = true
          // 動的に placeholderを変える
          window.$('.tagIt.social').data("ui-tagit").tagInput.attr('placeholder', 'グループを追加');
        }),
      });
      window.$('.tagIt.media').tagit({
        placeholderText: "スペースを追加",
        fieldName: 'alert_media',
        singleFieldDelimiter: ',',
        singleField: true,
        readOnly: false,
        availableTags: this.fetch_data.media_rooms.map(value => value.title),
        showAutocompleteOnFocus: true,
        preprocessTag: ((tagLabel) => {
          if (!tagLabel) return tagLabel;
          return tagLabel;
        }),
        afterTagAdded: (() => {
          this.alert_spaces_changed = true
          // 動的に placeholderを変える
          if (window.$('input[name="alert_media"]').val().split(',').length === this.fetch_data.media_rooms.length) {
            window.$('.tagIt.media').data("ui-tagit").tagInput.attr('placeholder', '全て選択中');
          }
        }),
        afterTagRemoved: (() => {
          this.alert_spaces_changed = true
          // 動的に placeholderを変える
          window.$('.tagIt.media').data("ui-tagit").tagInput.attr('placeholder', 'スペースを追加');
        }),
      });
    },
    snsRankClicked(rank) {
      this.$router.push({name: 'SnsTimeline', params: {user_id: rank.user.id}})
    },
    rankingClass(rank) {
      return sprintf('r%02d', rank.rank)
    },
    rankClass(rank,) {
      if (rank && rank.old_rank && rank.rank < rank.old_rank) {
        return 'rank_up'
      } else if (rank && rank.old_rank && rank.rank > rank.old_rank) {
        return 'rank_down'
      } else if (rank && rank.old_rank && rank.rank === rank.old_rank) {
        return 'no_change'
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
.ui-widget-content.ui-autocomplete-input {
  height: auto;
  line-height: normal;
}

.tagit-autocomplete {
  max-height: 300px;
  overflow-y: scroll;
  -ms-overflow-style: none;
}

.tagit-choice {
  display: inline-block !important;
  background: #2DA7CC !important;
  border-color: #2DA7CC !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: bold !important;
  padding: 2.5px 30px 2.5px 10px !important;
  margin: 5px 5px 5px 0 !important;
  border-radius: 3px !important;
  cursor: pointer !important;
}

table.type01 tr.data {
  cursor: pointer !important;
}
</style>
